import camioneta from "../assets/images/Icono-camioneta.svg";
import automovil from "../assets/images/icono-coche.svg";
import moto from "../assets/images/icono-moto.svg";

export const getVehicleType = (vehicle: string) => {
  if (vehicle.toLowerCase() === "motos" || vehicle.toLowerCase() === "moto")
    return moto;
  if (
    vehicle.toLowerCase() === "automoviles" ||
    vehicle.toLowerCase() === "automovil" ||
    vehicle.toLowerCase() === "car" ||
    vehicle.toLowerCase() === "coche"
  )
    return automovil;
  return camioneta;
};
