import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";

import welcomeCheckGreen from "../assets/images/WelcomeCheckGreen.svg";
import googlePlay from "../assets/images/googlePlay.svg";
import appStore from "../assets/images/appStore.svg";
import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { socket } from "../utils/socket";
import { ISocketNotification } from "../hooks/useHome";
import { useNavigate } from "react-router-dom";

interface Props {
  isZurich?: boolean;
}

export const GoodWelcome = ({ isZurich }: Props) => {
  const { breakpoints, palette } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isTablet = useMediaQuery(breakpoints.down("md"));
  const isDesktop = useMediaQuery(breakpoints.up("lg"));

  const navigate = useNavigate();

  useEffect(() => {
    let cookies = new Cookies();
    let _tid = cookies.get("_tid");
    let _user = cookies.get("_user");

    function onConnect() {
      window.console.log("Connected with the socket");
    }

    if (!_tid) {
      navigate("/signin");
    } else {
      socket.io.opts.query = {
        _u: _user,
      };
      socket.connect();
      socket.on("connect", onConnect);
      socket.on("identity", (data: ISocketNotification) => {});
    }
  }, [navigate, socket]);

  return (
    <Box
      padding={`${isMobile ? "0px 20px" : "30px"}`}
      marginTop={isTablet ? 2 : 0}
      marginBottom="45px"
      width={isTablet ? "auto" : "60%"}
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        margin: `${isMobile ? "0px 0px 60px 0px" : "30px auto 50px auto"}`,
      }}
      textAlign="center"
    >
      <Box
        component={"img"}
        src={welcomeCheckGreen}
        sx={{
          width: 80,
        }}
        alt="Bienvenido"
        style={{ objectFit: "contain" }}
        margin="25px 0px 20px 0px"
      />
      <Typography
        style={{ fontWeight: 700 }}
        fontSize={`${isDesktop ? "28px" : "20px"}`}
      >
        ¡Ya eres parte de Inter!
      </Typography>
      <Typography
        style={{
          fontWeight: 700,
          maxWidth: "600px",
          wordSpacing: "2px",
        }}
        fontSize="16px"
        margin="20px auto "
      >
        Gracias por ayudarnos a construir un mundo cada vez más seguro, seguro.
        ¡Ya está lista tu póliza!
      </Typography>
      <Typography
        fontSize={`${isDesktop ? "16px" : "16px"}`}
        margin="8px 0px 20px 0px"
        fontWeight="400"
      >
        Hoy seguro que duermes seguro <br />{" "}
        <span role="img" aria-label="arrow">
          😴
        </span>{" "}
        <span role="img" aria-label="arrow">
          ✨
        </span>
      </Typography>
      <Typography
        style={{
          fontWeight: 700,
          maxWidth: "600px",
          wordSpacing: "2px",
        }}
        fontSize="16px"
        margin="20px auto  0px auto "
      >
        ¡Checa tu correo!
      </Typography>
      <Typography
        style={{ fontWeight: 400 }}
        fontSize="16px"
        margin="0px 20px 20px 20px"
        padding={isDesktop ? "0px 130px" : "0px"}
      >
        En un momentito te enviaremos el resumen de tu compra y tu póliza.
      </Typography>
      <Typography
        style={{
          fontWeight: 700,
          maxWidth: "600px",
          wordSpacing: "2px",
        }}
        fontSize="16px"
        margin="0px auto "
      >
        ¡Recuerda descargar la App INTER.MX!
      </Typography>
      <Typography
        style={{ fontWeight: 400, textAlign: "center" }}
        fontSize="16px"
        margin="26px 0px 20px 0px"
        padding={isDesktop ? "0px 130px" : "0px"}
      >
        Para que puedas llevar a donde sea todos tus seguros y encontrar
        exclusivos beneficios por ser parte de
        <strong> inter.mx</strong>
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box marginTop="23px">
          <Box
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=grut.inter.mx",
                "_blank",
              )
            }
            component="img"
            src={googlePlay}
            sx={{
              width: 110,
            }}
            alt="Google Play"
            style={{ objectFit: "contain", cursor: "pointer" }}
            margin="0px 10px 6px"
          />
          <Box
            onClick={() =>
              window.open(
                "https://apps.apple.com/mx/app/intermx/id1548367767?l=en",
                "_blank",
              )
            }
            component="img"
            src={appStore}
            sx={{
              width: 110,
            }}
            alt="App Store"
            style={{ objectFit: "contain", cursor: "pointer" }}
            margin="20px 10px 6px"
          />
          <Typography
            fontSize="16px"
            marginTop={`${isDesktop ? "23px" : "50px"}`}
            style={{
              maxWidth: "500px",
            }}
          >
            ¿Te confundiste o no sabes qué onda? Nosotros te ayudamos, échanos
            un telefonazo al
            <a href="tel:+525544246837">
              {/*<CustomButton*/}
              {/*  text="55 4424 6837"*/}
              {/*  onClick={() => null}*/}
              {/*  size="small"*/}
              {/*  color={'inherit'}*/}
              {/*  variant={'text'}*/}
              {/*  style={{*/}
              {/*    color: palette.primary.main,*/}
              {/*    textTransform: 'none',*/}
              {/*    fontWeight: 'normal',*/}
              {/*    textDecoration: 'underline',*/}
              {/*  }}*/}
              {/*/>*/}
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
