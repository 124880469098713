import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { getVehicleType } from "../utils/vehicleType";
import { useDetailsComponent } from "../hooks/useDetailsComponent";
import { convertCurrency } from "../utils/convertCurrency";

interface DetailsProps {
  total: number;
  vehicleType: string;
  marca: string;
  anio: string;
  niv: string;
  placas: string;
  vigencia: number;
  plan: number;
}

const DetailsComponent: React.FC<DetailsProps> = ({
  total,
  vehicleType,
  marca,
  anio,
  niv,
  placas,
  vigencia,
  plan,
}) => {
  const { isMobile, isTablet } = useDetailsComponent();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        padding={`${isMobile ? "0px" : "30px"}`}
        marginTop={isTablet ? 2 : 0}
        width={"450px"}
        style={{
          backgroundColor: "#f6f6f6",
          borderRadius: "10px",
        }}
      >
        <Box
          style={{
            borderBottomStyle: "dashed",
            borderBottom: "1px dashed #5E6A71",
          }}
          padding={`${isMobile ? "14px" : "0px"}`}
        >
          <Box textAlign="center">
            <Box
              component={"img"}
              src={getVehicleType(vehicleType.toLowerCase())}
              sx={{
                width: 148,
              }}
              alt={"camioneta"}
              style={{ objectFit: "contain" }}
            />
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", color: "#424242" }}
              textAlign="start"
              fontSize={isTablet ? 15 : 18}
              margin={`${!isMobile ? "25px 0px 0px 0px" : "0px 0px 0px 0px"}`}
            >
              Vehículo
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            margin="15px 0px"
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", color: "#424242" }}
              textAlign="start"
              fontSize={isTablet ? 13 : 15}
              margin={`${!isMobile ? "25px 0px 0px 0px" : "0px 0px 0px 0px"}`}
            >
              Marca/Modelo:
            </Typography>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <TextField
                size={"small"}
                variant="outlined"
                style={{
                  borderRadius: "10px",
                  fontSize: "12px",
                  width: "320px",
                }}
                value={marca}
                onChange={() => {}}
                disabled={true}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", color: "#424242" }}
              textAlign="start"
              fontSize={isTablet ? 13 : 15}
            >
              Año:
            </Typography>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <TextField
                size={"small"}
                variant="outlined"
                style={{
                  borderRadius: "10px",
                  fontSize: "12px",
                  width: "80px",
                }}
                value={anio}
                onChange={() => {}}
                disabled={true}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            margin="15px 0px"
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", color: "#424242" }}
              textAlign="start"
              fontSize={isTablet ? 13 : 15}
            >
              Número de serie (NIV):
            </Typography>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <TextField
                size="small"
                variant="outlined"
                style={{
                  borderRadius: "10px",
                  fontSize: "12px",
                  width: "250px",
                }}
                value={niv}
                onChange={() => {}}
                disabled={true}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            margin="15px 0px 25px"
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", color: "#424242" }}
              textAlign="start"
              fontSize={isTablet ? 13 : 15}
            >
              Número de placas (opcional)
            </Typography>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{
                  borderRadius: "10px",
                  fontSize: "12px",
                }}
                value={placas}
                onChange={() => {}}
                disabled={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: "10px",
          width: "350px",
          padding: "25px",
          border: "1px solid gray",
          borderRadius: "20px",
        }}
      >
        <Box
          style={{
            borderBottomStyle: "dashed",
            borderBottom: "1px dashed #5E6A71",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold" }}
              textAlign="start"
              fontSize={16}
            >
              Detalle
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "bold" }}
              textAlign="start"
              fontSize={15}
            ></Typography>
          </Box>
          <Box
            marginTop={2}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal" }}
              textAlign="start"
              fontSize={15}
            >
              Total
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "bold" }}
              textAlign="start"
              fontSize={15}
            >
              {`$${convertCurrency(total)}`}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop={1}
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal" }}
              textAlign="start"
              fontSize={15}
            >
              Modalidad de pago
            </Typography>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Typography
                variant="caption"
                style={{ fontWeight: "bold" }}
                fontSize={15}
              >
                Contado
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop={1}
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal" }}
              textAlign="start"
              fontSize={15}
            >
              Tipo de plan
            </Typography>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Typography
                variant="caption"
                style={{ fontWeight: "bold" }}
                fontSize={15}
              >
                {plan === 10
                  ? "Amplia 40"
                  : plan === 20
                    ? "Amplia 20"
                    : "RC Básica"}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop={1}
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal" }}
              textAlign="start"
              fontSize={15}
            >
              Vigencia
            </Typography>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Typography
                variant="caption"
                style={{ fontWeight: "bold" }}
                fontSize={15}
              >
                {vigencia === 6 ? "Semestral" : "Anual"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          marginTop={4}
          borderRadius={2}
        >
          <Box
            width={278}
            height={70}
            borderRadius={"10px 0 0 10px "}
            style={{ backgroundColor: "#e3f2fd" }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              height={70}
            >
              <Box
                component={"img"}
                src={
                  "https://e7.pngegg.com/pngimages/540/1020/png-clipart-mapfre-insurance-oficina-seguros-mapfre-mapfre-seguros-gerais-s-a-logo-farmers-insurance-text-trademark.png"
                }
                sx={{
                  width: "55%",
                  height: "100%",
                  margin: "0",
                  padding: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                alt={"company logo"}
                style={{ objectFit: "contain" }}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            width={208}
            height={70}
            borderRadius={"0 10px 10px 0"}
            style={{ backgroundColor: "#e8eaf6" }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              padding={1}
            >
              <Typography variant="subtitle2" color="#8F8F8F" fontSize={16}>
                Costo
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: "bold", display: "flex", gap: "0.5rem" }}
                fontSize={17}
              >
                <span>$</span>
                {convertCurrency(total)}
              </Typography>
              <Typography
                fontSize={12}
                style={{ color: "#8F8F8F" }}
              ></Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailsComponent;
