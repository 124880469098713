import { create } from "zustand";
import { PolicyInputsStore } from "./types";

interface NewSell {
  isNewSell: boolean;
  setIsNewSell: (isNewSell: boolean) => void;
  isCopsis: boolean;
  setIsCopsis: (isCopsis: boolean) => void;
}

export const useNewSellStore = create<NewSell>((set) => ({
  isNewSell: false,
  setIsNewSell: (isNewSell) => {
    set({ isNewSell });
  },
  isCopsis: false,
  setIsCopsis: (isCopsis) => {
    set({ isCopsis });
  },
}));
