import { usePolicyInputsStore } from "../store/usePolicyStore";
import { useEffect } from "react";

export const useResetInputs = () => {
  const { setInputValue } = usePolicyInputsStore();

  const resetInputs = () => {
    setInputValue("", "nombre");
    setInputValue("", "marca");
    setInputValue("", "anio");
    setInputValue("", "niv");
    setInputValue("", "placas");
    setInputValue("", "apellido1");
    setInputValue("", "apellido2");
    setInputValue("", "fechaNacimiento");
    setInputValue("", "rfc");
    setInputValue("", "contratorEmail");
    setInputValue("", "telefono");
    setInputValue("", "numeroColaborador");
    setInputValue("", "domicilioFiscal");
    setInputValue("1", "sexo");
    setInputValue("", "zip");
    setInputValue("", "estado");
    setInputValue("", "colonia");
    setInputValue("", "calle");
    setInputValue("", "numExterior");
    setInputValue("", "numInterior");
    setInputValue("false", "isQuoteError");
    setInputValue("", "estadoCodigo");
    setInputValue("", "township_code");
    setInputValue("", "tipoVehiculoNewSell");
    setInputValue("", "marcaNewSell");
    setInputValue("", "anioNewSell");
    setInputValue("", "modeloNewSell");
  };

  return {
    resetInputs,
  };
};
