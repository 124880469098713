import { request } from "./index";

export const payment = async (quoteId: string) => {
  try {
    const { data } = await request().get(
      `/payment/check?business=cocheseguro&insurance=mapfre-down-sell&quote_id=${quoteId}`,
    );
    return data;
  } catch (e) {
    return e;
  }
};
