import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  SelectChangeEvent,
} from "@mui/material";
import { getVehicleType } from "../utils/vehicleType";
import { useLeftCard } from "../hooks/useLeftCard";
import { requestV1 } from "../api";
import { initialCarListData, useCarInfo } from "../store/usePolicyStore";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNewSellStore } from "../store/useNewSell";

export interface CarListData {
  vehicle_id: string;
  description: string;
  assembler: string;
  model: string;
  type: string;
  insurers: string[];
}

interface Data {
  [x: string]: any;
  modelo: string;
  anio: string;
  marca: string;
}

interface Car {
  car: Data;
  setCar: (carInfo: Data) => void;
}

export type VehiculoType = "CAR" | "MOTO" | "PICKUP";

const mockData: CarListData[] = [
  {
    vehicle_id: "609068-888712-248119-1649164012",
    description: "YARIS SEDAN CORE STD 1.5L 4CIL 4PTAS",
    assembler: "TOYOTA",
    model: "2015",
    type: "Car",
    insurers: [
      "zurich",
      "mapfre",
      "gnp",
      "aig",
      "qualitas",
      "axa",
      "aba",
      "primeroseguros",
      "hdi",
    ],
  },
];

export const LeftCard: React.FC = () => {
  const {
    isMobile,
    isTablet,
    request_front,
    setInputValue,
    marca,
    anio,
    niv,
    placas,
    nombre,
    apellido1,
    apellido2,
    sexo,
    handleSexo,
    fechaNacimiento,
    rfc,
    contratorEmail,
    telefono,
    domicilioFiscal,
    numeroColaborador,
    zip,
    estado,
    colonia,
    calle,
    numExterior,
    numInterior,
    colonias,
    handleColonia,
    isNewSell,
    getCatalogsLocationNewSell,
    tipoVehiculoNewSell,
    marcaNewSell,
    anioNewSell,
    modeloNewSell,
    disabledInputs,
    setColonias,
  } = useLeftCard();
  const { isCopsis } = useNewSellStore();

  const { carInfo, setCarInfo, selectedCarInfo, setSelectedCarInfo } =
    useCarInfo();

  const [assamblerList, setAssamblerList] = useState(["Toyota"]);
  const [carInfoList, setCarInfoList] = useState<CarListData[]>([
    initialCarListData,
  ]);
  const [tipoDeVehiculo, setTipoDeVehiculo] = useState<VehiculoType>("CAR");
  const { setEnableCotizar } = useCarInfo();

  const getAssamblerList = async (type: string) => {
    try {
      const { data } = await requestV1().get(
        `/catalogs/assembler?type=${type}`,
      );
      return data.data;
    } catch (e) {
      return mockData;
    }
  };

  const getAssemblerList = async (type: string) => {
    const data: string[] = await getAssamblerList(type);
    const assamblerList: string[] = data as string[];
    setAssamblerList(assamblerList);
  };

  useEffect(() => {
    if (!disabledInputs) setInputValue("", "anio");
    getAssemblerList(tipoDeVehiculo);
  }, [tipoDeVehiculo]);

  const getCarListApi = async (
    year: string,
    assembler: string,
    type: string,
  ) => {
    try {
      const { data } = await requestV1().get(
        `/catalogs/vehicle/cocheseguro/search?assembler=${assembler.toUpperCase()}&mod=${year}&type=${type}`,
      );
      return data.data;
    } catch (e) {
      return mockData;
    }
  };

  const getCarList = async (assembler: string, year: string, type: string) => {
    const data: any = await getCarListApi(year, assembler, type);
    data.filter((item: any) => item.description.includes(assembler));
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const carInfoList: CarListData[] =
      data.length === 0 ? [initialCarListData] : data;
    setCarInfoList(carInfoList);
  };

  useEffect(() => {
    if (selectedCarInfo.assembler && anio && anio.length === 4) {
      getCarList(selectedCarInfo.assembler, anio, tipoDeVehiculo);
    }
  }, [selectedCarInfo.assembler, anio]);

  useEffect(() => {
    if (!disabledInputs) setSelectedCarInfo(initialCarListData);
  }, [tipoDeVehiculo]);

  useEffect(() => {
    const { assembler, description, model } = selectedCarInfo;
    if (assembler && description && model) {
      setEnableCotizar(true);
    } else {
      setEnableCotizar(false);
    }
    if (assembler || description || model) {
      setCarInfo({ ...carInfo, ...selectedCarInfo });
    }
  }, [selectedCarInfo]);

  const [value, setValue] = useState(dayjs().format("YYYY-MM-DD"));

  const handleChange = (newValue: any) => {
    setValue(newValue);
    setInputValue(dayjs(newValue).format("YYYY-MM-DD"), "fechaNacimiento");
  };

  useEffect(() => {
    setValue(fechaNacimiento);
  }, [fechaNacimiento]);

  return (
    <Box
      padding={`${isMobile ? "0px" : "30px"}`}
      marginTop={isTablet ? 2 : 0}
      width={"450px"}
      style={{
        backgroundColor: "#f6f6f6",
        borderRadius: "10px",
      }}
    >
      <Box
        style={{
          borderBottomStyle: "dashed",
          borderBottom: "1px dashed #5E6A71",
        }}
        padding={`${isMobile ? "14px" : "0px"}`}
      >
        <Box textAlign="center">
          <Box
            component={"img"}
            src={getVehicleType(
              request_front.vehicle_type.toLowerCase() || tipoDeVehiculo,
            )}
            sx={{
              width: 148,
            }}
            alt={"camioneta"}
            style={{ objectFit: "contain" }}
          />
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 15 : 18}
            margin={`${!isMobile ? "25px 0px 0px 0px" : "0px 0px 0px 0px"}`}
          >
            Vehículo
          </Typography>
        </Box>
        {isNewSell && (
          <>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              marginTop={2}
            >
              <Typography
                variant="subtitle2"
                style={{ fontWeight: "normal", color: "#424242" }}
                textAlign="start"
                fontSize={isTablet ? 13 : 15}
              >
                Tipo de vehiculo:
              </Typography>
              <FormControl variant="outlined" sx={{ width: "200px" }}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={tipoDeVehiculo}
                  onChange={(event: SelectChangeEvent<string>) => {
                    setTipoDeVehiculo(event.target.value as VehiculoType);
                  }}
                  label="Vigencia"
                  size="small"
                >
                  <MenuItem value={"CAR"}>Coche</MenuItem>
                  {/*<MenuItem value={"MOTO"}>Moto</MenuItem>*/}
                  <MenuItem value={"PICKUP"}>Pick up</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FormControl
              fullWidth
              sx={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Autocomplete
                sx={{
                  flex: 1,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px 0 0 8px",
                  },
                }}
                options={assamblerList}
                value={selectedCarInfo.assembler}
                onChange={(event: any, newValue: any) => {
                  const upperCaseValue = (newValue || "").toUpperCase();

                  if (anio === "") setSelectedCarInfo(initialCarListData);

                  setSelectedCarInfo({
                    ...selectedCarInfo,
                    assembler: upperCaseValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Escribe o selecciona la marca"
                    value={selectedCarInfo.assembler}
                    onChange={({ target: { value } }) => {
                      const upperCaseValue = (value || "").toUpperCase();
                      setSelectedCarInfo({
                        ...selectedCarInfo,
                        assembler: upperCaseValue,
                      });
                    }}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
              <TextField
                variant="outlined"
                placeholder={"Año"}
                label={"Año"}
                sx={{
                  borderRadius: "40px 0",
                  fontSize: "12px",
                  width: "80px",
                  height: "100%",
                }}
                InputProps={{
                  sx: {
                    height: "100%",
                  },
                }}
                value={anio}
                onChange={(e) => setInputValue(e.target.value, "anio")}
              />
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: "15px" }}>
              <Autocomplete
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
                value={selectedCarInfo}
                options={carInfoList}
                getOptionLabel={(option) =>
                  selectedCarInfo.assembler === ""
                    ? ""
                    : option && `${option?.description ?? ""}`
                }
                onChange={(event: any, newValue: any) => {
                  const upperCaseValue = (
                    (newValue && newValue.description) ||
                    ""
                  ).toUpperCase();

                  if (!newValue?.description) {
                    setSelectedCarInfo({ ...selectedCarInfo, description: "" });
                    return;
                  }

                  setSelectedCarInfo({
                    ...newValue,
                    description: upperCaseValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={disabledInputs}
                    label="Escribe o selecciona el modelo"
                    value={""}
                    onChange={({ target: { value } }) => {
                      const upperCaseValue = (value || "").toUpperCase();
                      setSelectedCarInfo({
                        ...selectedCarInfo,
                        description: upperCaseValue,
                      });
                    }}
                  />
                )}
              />
            </FormControl>
          </>
        )}
        {!isNewSell && (
          <>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              margin="15px 0px"
            >
              <Typography
                variant="subtitle2"
                style={{ fontWeight: "normal", color: "#424242" }}
                textAlign="start"
                fontSize={isTablet ? 13 : 15}
                margin={`${!isMobile ? "25px 0px 0px 0px" : "0px 0px 0px 0px"}`}
              >
                Marca/Modelo:
              </Typography>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <TextField
                  disabled={isNewSell ? false : true}
                  size={"small"}
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    fontSize: "12px",
                    width: "320px",
                  }}
                  value={marca}
                  onChange={(e) => setInputValue(e.target.value, "marca")}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle2"
                style={{ fontWeight: "normal", color: "#424242" }}
                textAlign="start"
                fontSize={isTablet ? 13 : 15}
              >
                Año:
              </Typography>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <TextField
                  disabled={isNewSell ? false : true}
                  size={"small"}
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    fontSize: "12px",
                    width: "80px",
                  }}
                  value={anio}
                  onChange={(e) => setInputValue(e.target.value, "anio")}
                />
              </Box>
            </Box>
          </>
        )}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          margin="15px 0px"
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Número de serie (NIV):
          </Typography>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <TextField
              size="small"
              variant="outlined"
              style={{
                borderRadius: "10px",
                fontSize: "12px",
                width: "250px",
              }}
              value={niv}
              onChange={(e) => setInputValue(e.target.value, "niv")}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          margin="15px 0px 25px"
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Número de placas (opcional)
          </Typography>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              style={{
                borderRadius: "10px",
                fontSize: "12px",
              }}
              value={placas}
              onChange={(e) => setInputValue(e.target.value, "placas")}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          margin="15px 0px 25px"
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            Número de colaborador
            <Typography sx={{ fontSize: 25, marginLeft: 1 }} color={"error"}>
              *
            </Typography>
          </Typography>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              style={{
                borderRadius: "10px",
                fontSize: "12px",
              }}
              value={numeroColaborador}
              onChange={(e) =>
                setInputValue(e.target.value, "numeroColaborador")
              }
            />
          </Box>
        </Box>
      </Box>
      <Box
        marginTop={2}
        padding={`${isMobile ? "14px" : "0px"}`}
        style={
          isTablet
            ? {
                borderBottomStyle: "dashed",
                borderBottom: "1px dashed #5E6A71",
              }
            : {}
        }
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 15 : 15}
          >
            Contratante
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Nombre:
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              fontSize: "12px",
              width: "300px",
            }}
            value={nombre}
            onChange={(e) => {
              setInputValue(e.target.value, "nombre");
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Apellido Materno:
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              fontSize: "12px",
              width: "300px",
            }}
            value={apellido1}
            onChange={(e) => {
              setInputValue(e.target.value, "apellido1");
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Apellido Paterno:
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              fontSize: "12px",
              width: "300px",
            }}
            value={apellido2}
            onChange={(e) => {
              setInputValue(e.target.value, "apellido2");
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Sexo de nacimiento:
          </Typography>
          <FormControl variant="outlined" sx={{ width: "200px" }}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={sexo}
              onChange={handleSexo}
              label="Vigencia"
              size="small"
            >
              <MenuItem value={1}>Masculino</MenuItem>
              <MenuItem value={2}>Femenino</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Fecha de nacimiento:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Fecha de nacimiento"
              inputFormat="YYYY-MM-DD"
              value={value}
              onChange={handleChange}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            RFC:
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              width: "250px",
            }}
            value={rfc}
            onChange={(e) => setInputValue(e.target.value, "rfc")}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Correo electrónico:
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              width: "250px",
            }}
            value={contratorEmail}
            onChange={(e) => setInputValue(e.target.value, "contratorEmail")}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Teléfono:
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              width: "200px",
            }}
            value={telefono}
            onChange={(e) => setInputValue(e.target.value, "telefono")}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
          marginBottom={3}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Codigo Postal:
          </Typography>
          <TextField
            onBlur={() => getCatalogsLocationNewSell(zip)}
            InputProps={{
              rows: 3,
            }}
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              fontSize: "12px",
            }}
            value={zip}
            onChange={(e) => setInputValue(e.target.value, "zip")}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
          marginBottom={3}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Estado:
          </Typography>
          <TextField
            InputProps={{
              rows: 3,
            }}
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              fontSize: "12px",
            }}
            value={estado}
            onChange={(e) => setInputValue(e.target.value, "estado")}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            Colonia
            <Typography sx={{ fontSize: 25, marginLeft: 1 }} color={"error"}>
              *
            </Typography>
          </Typography>
          <FormControl variant="outlined" sx={{ width: "200px" }}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={colonia}
              onChange={handleColonia}
              label="Vigencia"
              size="small"
            >
              {colonias.map((colonia) => (
                <MenuItem value={colonia.suburb_code}>
                  {colonia.suburb_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
          marginBottom={3}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            Calle
            <Typography sx={{ fontSize: 25, marginLeft: 1 }} color={"error"}>
              *
            </Typography>
          </Typography>
          <TextField
            multiline
            InputProps={{
              rows: 3,
            }}
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              fontSize: "12px",
              width: "300px",
            }}
            value={calle}
            onChange={(e) => setInputValue(e.target.value, "calle")}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
          marginBottom={3}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            No. exterior{" "}
            <Typography sx={{ fontSize: 25, marginLeft: 1 }} color={"error"}>
              *
            </Typography>
          </Typography>
          <TextField
            InputProps={{
              rows: 3,
            }}
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              fontSize: "12px",
            }}
            value={numExterior}
            onChange={(e) => setInputValue(e.target.value, "numExterior")}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
          marginBottom={3}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal", color: "#424242" }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            No. interior
          </Typography>
          <TextField
            InputProps={{
              rows: 3,
            }}
            size="small"
            variant="outlined"
            style={{
              borderRadius: "10px",
              fontSize: "12px",
            }}
            value={numInterior}
            onChange={(e) => setInputValue(e.target.value, "numInterior")}
          />
        </Box>
        {isTablet ? (
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box
              width={30}
              height={30}
              borderRadius={100}
              left={isMobile ? -20 : isTablet ? 0 : 0}
              top={isMobile ? "47%" : isTablet ? "44.5%" : "54.8%"}
              position={"absolute"}
              style={{ backgroundColor: "#f5f5f5" }}
            />
            <Box
              width={30}
              height={30}
              borderRadius={100}
              left={isMobile ? "97%" : isTablet ? 680 : 580}
              top={isMobile ? "47%" : isTablet ? "44.5%" : "54.8%"}
              position={"absolute"}
              style={{ backgroundColor: "#f5f5f5" }}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
