import React from "react";
import HomeDownSell from "./components/HomeDownSell";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DetailsPage from "./pages/Details";
import { themeOptions } from "./theme/theme";
import { createTheme, responsiveFontSizes } from "@mui/material";

import SignIn from "./pages/SignIn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useApp } from "./hooks/useApp";
import { GoodWelcome } from "./pages/Welcome";
import { FailWelcome } from "./pages/Fail";

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

function App() {
  useApp();

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
    >
      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/home" element={<HomeDownSell />} />
          <Route path="/details/:emissionId" element={<DetailsPage />} />
          <Route path="/welcome" element={<GoodWelcome />} />
          <Route path="/fail" element={<FailWelcome />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
