import { create } from "zustand";
import { PolicyInputsStore, PolicyStore, Root } from "./types";
import React, { useState } from "react";
import { CarListData } from "../components/LeftCard";

export const initialPolicyState = {
  policyUrl: "",
  copsis: false,
  insurance: "",
  updated_at: "",
  business: "",
  policy_id: "",
  created_at: "",
  vin: "",
  emission_date: "",
  url: "",
  createTime: "",
  updateTime: "",
  id: "",
  emission: {
    policy_id: "",
    policy_paid: false,
    request_front: {
      insurance: "",
      driver_last_name: "",
      vehicle_plate: "",
      driver_street: "",
      contractor_suburb_code: "",
      promo_code: "",
      associate_id: "",
      renovacion: false,
      legal_suburb_code: "",
      contractor_rfc: "",
      driver_gender: "",
      vehicle_vin: "",
      legal_state_code: "",
      legal_int: "",
      legal_zip_code: "",
      payment_plan: "",
      contractor_phone: "",
      legal_street: "",
      prima_total: 0,
      package: "",
      cfdi_tax_regime: "",
      contractor_ext: "",
      quote_id: "",
      driver_int: "",
      policy_paid: false,
      driver_phone: "",
      driver_first_name: "",
      driver_zip_code: "",
      contractor_email: "",
      contractor: "",
      driver_suburb_code: "",
      contractor_street: "",
      policy_id: "",
      vehicle_description: "",
      vehicle_model: "",
      contractor_gender: "",
      start_at: "",
      driver_state_code: "",
      driver_location: "",
      driver_second_last_name: "",
      use_tax_regime: "",
      contractor_int: "",
      vehicle_service: "",
      contractor_first_name: "",
      driver_township_code: "",
      vehicle_id: "",
      tracking_id: "",
      contractor_lastname: "",
      driver_mail: "",
      contractor_birth_date: "",
      contractor_second_lastname: "",
      business: "",
      legal_township_code: "",
      payment_option: "",
      legal_ext: "",
      driver_rfc: "",
      vehicle_use: "",
      vehicle_type: "",
      contractor_township_code: "",
      payment_card: "",
      emision_date: "",
      driver_birth_date: "",
      contractor_zip_code: "",
      driver_ext: "",
      invoice: "",
      contractor_state_code: "",
    },
  },
};

const initialData = {
  marca: "",
  anio: "",
  niv: "",
  placas: "",
  name: "",
  sexo: "1",
  fechaNacimiento: "",
  rfc: "",
  contratorEmail: "",
  telefono: "",
  domicilioFiscal: "",
  numeroColaborador: "",
};

export const usePolicyInputsStore = create<PolicyInputsStore>((set) => ({
  marca: "",
  anio: "",
  niv: "",
  placas: "",
  nombre: "",
  apellido1: "",
  apellido2: "",
  sexo: "1",
  fechaNacimiento: "",
  rfc: "",
  contratorEmail: "",
  telefono: "",
  domicilioFiscal: "",
  numeroColaborador: "",
  zip: "",
  estado: "",
  estadoCodigo: "",
  colonia: "",
  calle: "",
  numExterior: "",
  numInterior: "",
  township_code: "",
  isQuoteError: "false",
  tipoVehiculoNewSell: "",
  marcaNewSell: "",
  anioNewSell: "",
  modeloNewSell: "",
  disabledInputs: false,
  colonias: [],
  setColonias: (colonias) => set({ colonias }),
  setDisabledInputs: (disabledInputs: boolean) => set({ disabledInputs }),
  setInputValue: (value, key) => {
    set({ [key]: value });
  },
}));

export const usePolicyStore = create<PolicyStore>((set) => ({
  policy: initialPolicyState,
  isLoading: false,
  total: -1,
  paymentUrl: "",
  quoteId: "",
  polizaInputText: "",
  serieInputText: "",
  isLoadingQuote: false,
  plan: "20",
  error: "",
  poliza: "",
  trackingId: "",
  emissionBody: {
    associate_id: "",
    business: "",
    contractor: "",
    contractor_birth_date: "",
    contractor_email: "",
    contractor_ext: "",
    contractor_first_name: "",
    contractor_gender: "",
    contractor_int: "",
    contractor_lastname: "",
    contractor_phone: "",
    contractor_rfc: "",
    contractor_second_lastname: "",
    contractor_state_code: "",
    contractor_street: "",
    contractor_suburb_code: "",
    contractor_township_code: "",
    contractor_zip_code: "",
    driver_birth_date: "",
    driver_ext: "",
    driver_first_name: "",
    driver_gender: "",
    driver_int: "",
    driver_last_name: "",
    driver_location: "",
    driver_mail: "",
    driver_phone: "",
    driver_rfc: "",
    driver_second_last_name: "",
    driver_state_code: "",
    driver_street: "",
    driver_suburb_code: "",
    driver_township_code: "",
    driver_zip_code: "",
    emision_date: "",
    insurance: "mapfre-down-sell",
    legal_agreement: [""],
    legal_ext: "",
    legal_int: "",
    legal_state_code: "",
    legal_street: "",
    legal_suburb_code: "",
    legal_township_code: "",
    legal_zip_code: "",
    package: "",
    package_id: "",
    payment_card: "",
    payment_option: "",
    payment_plan: "",
    promo_code: "",
    quote_id: "",
    start_at: "",
    tracking_id: "",
    vehicle_description: "",
    vehicle_id: "",
    vehicle_model: "",
    vehicle_plate: "",
    vehicle_service: "",
    vehicle_type: "",
    vehicle_use: "",
    vehicle_vin: "",
  },
  setEmissionBody: (emissionBody: any) => {
    set({ emissionBody });
  },
  setTrackingId: (trackingId: string) => {
    set({ trackingId });
  },
  setPoliza: (poliza: string) => {
    set({ poliza });
  },
  serie: "",
  setSerie: (serie: string) => {
    set({ serie });
  },
  setError: (error) => {
    set({ error });
  },
  setPlan: (plan) => {
    set({ plan });
  },
  vigencia: 12,
  setVigencia: (vigencia) => {
    set({ vigencia });
  },
  setIsLoadingQuote: (isLoadingQuote) => {
    set({ isLoadingQuote });
  },
  setPolicy: (policy) => {
    set({ policy });
  },
  setIsLoading: (isLoading) => {
    set({ isLoading });
  },
  setTotal: (total: number) => {
    set({ total });
  },
  setPaymentUrl: (paymentUrl: string) => {
    set({ paymentUrl });
  },
  setQuoteId: (quoteId: string) => {
    set({ quoteId });
  },
  setPolizaInputText: (polizaInputText: string) => {
    set({ polizaInputText });
  },
  setSerieInputText: (serieInputText: string) => {
    set({ serieInputText });
  },
}));

export const initialCarListData: CarListData = {
  vehicle_id: "",
  description: "",
  assembler: "",
  model: "",
  type: "",
  insurers: [
    "zurich",
    "mapfre",
    "gnp",
    "aig",
    "qualitas",
    "axa",
    "aba",
    "primeroseguros",
    "hdi",
  ],
};

interface CarStore {
  enableCotizar: boolean;
  setEnableCotizar: (enableCotizar: boolean) => void;
  carInfo: any;
  setCarInfo: (carInfo: any) => void;
  selectedCarInfo: CarListData;
  setSelectedCarInfo: (selectedCarInfo: CarListData) => void;
}

export const useCarInfo = create<CarStore>((set) => ({
  enableCotizar: false,
  carInfo: {},
  selectedCarInfo: initialCarListData,
  setSelectedCarInfo: (selectedCarInfo: any) => {
    set({ selectedCarInfo });
  },
  setCarInfo: (carInfo: any) => {
    set({ carInfo });
  },
  setEnableCotizar: (enableCotizar: boolean) => {
    set({ enableCotizar });
  },
}));

interface QuoteStore {
  isLoadingQuoteNewSell: boolean;
  setIsLoadingQuoteNewSell: (isLoadingQuoteNewSell: boolean) => void;
}

export const useQuoteNewSellStore = create<QuoteStore>((set) => ({
  isLoadingQuoteNewSell: false,
  setIsLoadingQuoteNewSell: (isLoadingQuoteNewSell: boolean) => {
    set({ isLoadingQuoteNewSell });
  },
}));
