import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../api";
import { EmissionById } from "../api/types";
import { useMediaQuery, useTheme } from "@mui/material";
import { useApp } from "./useApp";
import Cookies from "universal-cookie";
import { socket } from "../utils/socket";
import { ISocketNotification } from "./useHome";
import dayjs from "dayjs";

export const useDetailPage = () => {
  const [paymentUrl, setPaymentUrl] = useState("");
  const [total, setTotal] = useState(0);
  const [marca, setMarca] = useState("");
  const [anio, setAnio] = useState("");
  const [niv, setNiv] = useState("");
  const [placas, setPlacas] = useState("");
  const [vigencia, setVigencia] = useState(6);
  const [plan, setPlan] = useState(10);
  const [vehicleType, setVehicleType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);

  const { emissionId } = useParams<{ emissionId: string }>();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down("md"));

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      if (!sessionStorage.getItem("authorization")) {
        const { data: auth } = await request().post("/authorization/token", {
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
        });

        sessionStorage.setItem("authorization", auth.access_token);
      }

      const { data } = await request().get<EmissionById>(
        `/emission/${emissionId}`,
      );

      setPaymentUrl(data.response_front.payment_data.service_url);
      setTotal(+data.quote.Totales.prima_total);
      setMarca(data.request_front.vehicle_description);
      setAnio(data.request_front.vehicle_model);
      setNiv(data.request_front.vehicle_vin);
      setPlacas(data.request_front.vehicle_plate);

      setVigencia(
        dayjs(data.request_front.end_at).diff(
          dayjs(data.request_front.start_at),
          "months",
        ),
      );
      setPlan(
        data.request_front.package === "PAQ5"
          ? 10
          : data.request_front.package === "PAQ2"
            ? 20
            : 30,
      );
      setVehicleType(data.request_front.vehicle_type);
    })();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let cookies = new Cookies();
    let _tid = cookies.get("_tid");
    let _user = cookies.get("_user");

    function onConnect() {
      window.console.log("Connected with the socket");
    }

    if (!_tid) {
      setShowBackButton(false);
    } else {
      setShowBackButton(true);
      socket.io.opts.query = {
        _u: _user,
      };
      socket.connect();
      socket.on("connect", onConnect);
      socket.on("identity", (data: ISocketNotification) => {});
    }
  }, [socket, setShowBackButton]);

  return {
    paymentUrl,
    isTablet,
    total,
    anio,
    marca,
    niv,
    placas,
    plan,
    vehicleType,
    vigencia,
    showBackButton,
  };
};
