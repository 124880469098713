import { useState } from "react";
import {
  useCarInfo,
  usePolicyInputsStore,
  usePolicyStore,
  useQuoteNewSellStore,
} from "../store/usePolicyStore";
import { request, requestV1 } from "../api";
import { Root } from "../store/types";
import dayjs from "dayjs";
import { Quote } from "../api/types";
import { CatalogList } from "./useLeftCard";
import { useNewSellStore } from "../store/useNewSell";
import { useResetInputs } from "./useResetInputs";

export const useSearchPoliza = () => {
  const {
    setPolicy,
    setIsLoading,
    isLoading,
    setTotal,
    setQuoteId,
    setPolizaInputText,
    setSerieInputText,
    setIsLoadingQuote,
    setPlan,
    setVigencia,
    error,
    setError,
    poliza,
    serie,
    setPoliza,
    setSerie,
    vigencia,
    setTrackingId,
    setEmissionBody,
    isLoadingQuote,
  } = usePolicyStore();
  const {
    setInputValue,
    marca,
    anio,
    niv,
    placas,
    nombre,
    apellido1,
    apellido2,
    sexo,
    fechaNacimiento,
    rfc,
    contratorEmail,
    telefono,
    domicilioFiscal,
    numeroColaborador,
    zip,
    estado,
    estadoCodigo,
    colonia,
    calle,
    numExterior,
    numInterior,
    township_code,
    isQuoteError,
    tipoVehiculoNewSell,
    marcaNewSell,
    anioNewSell,
    modeloNewSell,
    setDisabledInputs,
  } = usePolicyInputsStore();
  const { setIsNewSell, isNewSell, isCopsis, setIsCopsis } = useNewSellStore();
  const { resetInputs } = useResetInputs();
  const { enableCotizar, carInfo, setCarInfo } = useCarInfo();
  const { setIsLoadingQuoteNewSell, isLoadingQuoteNewSell } =
    useQuoteNewSellStore();
  const [showQuoteButton, setShowQuoteButton] = useState(false);
  const [policyURL, setPolicyURL] = useState("");
  const [removePolicyUrl, setRemovePolicyUrl] = useState(false);
  const [quoteFinished, setQuoteFinished] = useState(false);

  const onSearchPress = async () => {
    if (poliza || serie) {
      resetInputs();
      setPolizaInputText(poliza);
      setSerieInputText(serie);
      setTotal(0);
      setPlan("20");
      setVigencia(12);
      setQuoteFinished(false);
      try {
        setError("");
        setIsLoading(true);
        const { data } = await request().get<Root>(
          `/policy?policy_id=${poliza}&vin=${serie}`,
        );

        setPolicy(data);
        setIsLoading(false);

        const {
          emission: { request_front },
        } = data;

        if (data.copsis) {
          setIsCopsis(true);
          setPolicyURL(data.policyUrl);
          setCarInfo({
            vehicle_id: request_front.vehicle_id,
            type: request_front.vehicle_type,
          });
          setShowQuoteButton(true);
          return;
        }

        setIsCopsis(false);

        const { data: trackingData } = await request().post<{
          data: { id: string };
        }>(`/tracking`, {
          business: "cocheseguro",
          data: data,
        });

        setTrackingId(trackingData.data.id);
        setInputValue(request_front.contractor_birth_date, "fechaNacimiento");

        const body = {
          tracking_id: trackingData.data.id,
          business: request_front.business,
          insurance: "mapfre-down-sell",
          contact_name: request_front.contractor_first_name,
          contact_method: "EMAIL",
          contact_phone: request_front.contractor_phone,
          contact_email: request_front.contractor_email,
          contact_gender: request_front.contractor_gender,
          contact_birth_date: request_front.contractor_birth_date,
          contact_state_code: request_front.contractor_state_code,
          contact_township_code: request_front.contractor_township_code,
          contact_suburb_code: request_front.contractor_suburb_code,
          contact_zip_code: request_front.contractor_zip_code,
          contact_now: true,
          vehicle_id: request_front.vehicle_id,
          vehicle_service: request_front.vehicle_service,
          vehicle_use: request_front.vehicle_use,
          vehicle_type: request_front.vehicle_type,
          payment_option: "A",
          package: "PAQ2",
          promotion_code: request_front.promo_code,
          associate_id: request_front.associate_id,
          quote_date: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
          start_at: dayjs().format("YYYY-MM-DD"),
          end_at: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
          custom_RT: "10",
          custom_DM: "5",
        };

        try {
          setIsLoadingQuote(true);
          const { data: quote } = await request().post<Quote>(`/quote`, body);
          setTotal(+quote.Totales.prima_total);
          setQuoteId(quote.quote_id);
          setIsLoadingQuote(false);
        } catch (e) {
          setInputValue("true", "isQuoteError");
          const {
            data: { data },
          } = await requestV1().get<CatalogList>(
            `/catalogs/location/${request_front.contractor_zip_code}`,
          );

          setInputValue(data[0].zip_code, "zip");
          setInputValue(data[0].state_name, "estado");
          setInputValue("", "colonia");
          setInputValue(data[0].state_name, "calle");

          const body = {
            tracking_id: trackingData.data.id,
            business: request_front.business,
            insurance: "mapfre-down-sell",
            contact_name: request_front.contractor_first_name,
            contact_method: "EMAIL",
            contact_phone: request_front.contractor_phone,
            contact_email: request_front.contractor_email,
            contact_gender: request_front.contractor_gender,
            contact_birth_date: request_front.contractor_birth_date,
            contact_state_code: request_front.contractor_state_code,
            contact_township_code: data[0].township_code,
            contact_suburb_code: data[0].suburb_code,
            contact_zip_code: request_front.contractor_zip_code,
            contact_now: true,
            vehicle_id: request_front.vehicle_id,
            vehicle_service: request_front.vehicle_service,
            vehicle_use: request_front.vehicle_use,
            vehicle_type: request_front.vehicle_type,
            payment_option: "A",
            package: "PAQ2",
            promotion_code: request_front.promo_code,
            associate_id: request_front.associate_id,
            quote_date: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
            start_at: dayjs().format("YYYY-MM-DD"),
            end_at: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
            custom_RT: "10",
            custom_DM: "5",
          };
          const { data: quote } = await request().post<Quote>(`/quote`, body);
          setTotal(+quote.Totales.prima_total);
          setQuoteId(quote.quote_id);
          setIsLoadingQuote(false);
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        setIsLoadingQuote(false);
        //@ts-ignore
        if (e.status === 500 || e) {
          setError(
            "Error en poliza o cotizacion, por favor intente nuevamente.",
          );
        }
        //@ts-ignore
        if (e.response && e.response.status === 404) {
          setError(
            "No se pudo encontrar registro con la Poliza or Serie ingresada, intenta con otro.",
          );
        }
      }
    } else {
      setError("Ingresar Poliza o Serie para realizar la busqueda.");
    }
  };

  const createQuote = async () => {
    setError("");
    setIsLoadingQuoteNewSell(false);
    const { data: trackingData } = await request().post<{
      data: { id: string };
    }>(`/tracking`, {
      business: "cocheseguro",
      data: {},
    });

    setTrackingId(trackingData.data.id);

    const body = {
      tracking_id: trackingData.data.id,
      business: "cocheseguro",
      insurance: "mapfre-down-sell",
      contact_name: nombre,
      contact_method: "EMAIL",
      contact_phone: telefono,
      contact_email: contratorEmail,
      contact_gender: +sexo === 1 ? "M" : "F",
      contact_birth_date: fechaNacimiento,
      contact_state_code: estadoCodigo,
      contact_township_code: township_code,
      contact_suburb_code: colonia,
      contact_zip_code: zip,
      contact_now: true,
      vehicle_id: carInfo?.vehicle_id,
      vehicle_service: "PARTICULAR",
      vehicle_use: "NORMAL",
      vehicle_type: carInfo?.type.toUpperCase(),
      payment_option: "A",
      package: "PAQ2",
      promotion_code: "",
      associate_id: numeroColaborador,
      quote_date: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
      start_at: dayjs().format("YYYY-MM-DD"),
      end_at: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
      custom_RT: "10",
      custom_DM: "5",
    };

    try {
      setIsLoadingQuote(true);
      const { data: quote } = await request().post<Quote>(`/quote`, body);

      if (quote && quote.Totales && quote.Totales.prima_total) {
        setTotal(+quote.Totales.prima_total);
        setQuoteId(quote.quote_id);
        setIsLoadingQuote(false);
        setDisabledInputs(true);
        setIsLoadingQuoteNewSell(true);

        setEmissionBody({
          tracking_id: trackingData.data.id,
          quote_id: quote.quote_id,
          business: "cocheseguro",
          insurance: "mapfre-down-sell",
          package: "PAQ2",
          payment_plan: "",
          payment_option: "A",
          payment_card: "",
          driver_first_name: nombre,
          driver_last_name: apellido1,
          driver_second_last_name: apellido2,
          driver_phone: telefono,
          driver_mail: contratorEmail,
          driver_gender: +sexo === 1 ? "M" : "F",
          driver_birth_date: fechaNacimiento,
          driver_location: "",
          driver_rfc: rfc,
          driver_zip_code: zip,
          driver_state_code: estadoCodigo,
          driver_township_code: township_code,
          driver_suburb_code: colonia,
          driver_street: calle,
          driver_ext: numExterior,
          driver_int: numInterior,
          vehicle_id: carInfo?.vehicle_id,
          vehicle_service: "PARTICULAR",
          vehicle_use: "NORMAL",
          vehicle_type: carInfo?.type.toUpperCase(),
          vehicle_vin: niv,
          vehicle_plate: placas,
          vehicle_model: carInfo?.model,
          legal_agreement: [
            {
              agreement:
                "Estoy de acuerdo con la descripción del vehículo seleccionada y misma que  se  muestra  en  la  parte superior de esta pantalla.",
              acceptance: true,
            },
            {
              agreement:
                "Mi vehículo no es utilizado ni será utilizado para presentar servicios de entrega por paquetería, a domicilio y/o similares.",
              acceptance: true,
            },
            {
              agreement:
                "No he recibido indemnización alguna de parte de alguna aseguradora derivado de la pérdida total de mi vehículo.",
              acceptance: true,
            },
            {
              agreement:
                "Mi vehículo no se encuentra chocado o siniestrado de alguna forma.",
              acceptance: true,
            },
            {
              agreement: "He leído y acepto el Aviso de privacidad.",
              acceptance: true,
            },
            {
              agreement:
                "Estoy de acuerdo con las Condiciones Generales y Términos de Uso.",
              acceptance: true,
            },
          ],
          contractor: "false",
          contractor_first_name: nombre,
          contractor_lastname: apellido1,
          contractor_second_lastname: apellido2,
          contractor_birth_date: fechaNacimiento,
          contractor_rfc: rfc,
          contractor_email: contratorEmail,
          contractor_phone: telefono,
          contractor_gender: +sexo === 1 ? "M" : "F",
          contractor_zip_code: zip,
          custom_DM: "5",
          custom_RT: "10",
          legal_zip_code: zip,
          legal_state_code: estadoCodigo,
          legal_township_code: township_code,
          legal_suburb_code: colonia,
          legal_street: calle,
          legal_ext: numExterior,
          legal_int: numInterior,
          promo_code: "",
          associate_id: numeroColaborador,
          invoice: "false",
          cfdi_tax_regime: "612",
          start_at: dayjs().format("YYYY-MM-DD"),
          end_at: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
          quote_date: dayjs().format("YYYY-MM-DD"),
          emision_date: dayjs().format("YYYY-MM-DD"),
          contractor_state_code: estadoCodigo,
          contractor_township_code: township_code,
          contractor_suburb_code: colonia,
          contractor_street: calle,
          contractor_ext: numExterior,
          contractor_int: numInterior,
        });
      } else {
        console.log("THIS IS ERROR");
        console.log(quote);
        // @ts-ignore
        const errors = quote?.data?.errors;
        let telefonoError = "";
        let emailError = "";
        let birthdayError = "";
        let vehicleError = "";
        errors.forEach((err: any) => {
          const key = Object.keys(err)[0];
          const errorMessage = err[key].message;
          console.log(`${key}: ${errorMessage}`);
          if (key === "contact_phone" && telefonoError === "") {
            telefonoError = "Revisa el formato Teléfono.";
          }
          if (key === "contact_email" && telefonoError === "") {
            emailError = "Email invalido. ";
          }
          if (key === "contact_birth_date" && telefonoError === "") {
            birthdayError =
              "El formato de Fecha de nacimiento debe ser AAAA-MM-DD.";
          }
          if (key === "vehicle_id" && vehicleError === "") {
            vehicleError = "El vehiculo seleccionado no posee ID de Mapfre.";
          }
        });
        setError(
          telefonoError +
            " " +
            emailError +
            " " +
            birthdayError +
            " " +
            vehicleError,
        );
        setIsLoadingQuote(false);
        setIsLoadingQuoteNewSell(false);
        return;
      }
      setIsLoadingQuote(false);
      setIsLoadingQuoteNewSell(true);
    } catch (e: any) {
      console.log(e);
      setIsLoadingQuote(false);
    }
    setShowQuoteButton(false);
    setRemovePolicyUrl(true);
    setPolicyURL("");
    setQuoteFinished(true);
  };

  return {
    poliza,
    setPoliza,
    serie,
    setSerie,
    error,
    onSearchPress,
    isLoading,
    setIsNewSell,
    isNewSell,
    marca,
    anio,
    niv,
    placas,
    nombre,
    apellido1,
    apellido2,
    sexo,
    fechaNacimiento,
    rfc,
    contratorEmail,
    telefono,
    domicilioFiscal,
    numeroColaborador,
    zip,
    estado,
    estadoCodigo,
    colonia,
    calle,
    numExterior,
    numInterior,
    township_code,
    isQuoteError,
    createQuote,
    tipoVehiculoNewSell,
    marcaNewSell,
    anioNewSell,
    modeloNewSell,
    resetInputs,
    enableCotizar,
    setPolicy,
    setTotal,
    setIsLoadingQuoteNewSell,
    isLoadingQuoteNewSell,
    isLoadingQuote,
    isCopsis,
    showQuoteButton,
    policyURL,
    setIsCopsis,
    setPolicyURL,
    removePolicyUrl,
    setRemovePolicyUrl,
    quoteFinished,
    setQuoteFinished,
  };
};
