import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { usePolicyStore } from "../store/usePolicyStore";
import { payment } from "../api/payment";

interface Props {
  url: string;
  showBackButton: boolean;
}

const IFrameComponent = ({ url, showBackButton }: Props) => {
  const navigate = useNavigate();
  const { quoteId } = usePolicyStore();

  const handleIframe = () => {
    (async () => {
      const data = await payment(quoteId);
      if (data?.policy_paid) {
        navigate("/welcome");
      } else {
      }
    })();
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Box
      style={{
        border: "1px solid gray",
        borderRadius: "20px",
        paddingRight: "10px",
      }}
    >
      {showBackButton && (
        <Button
          variant="contained"
          sx={{ position: "absolute", top: 30 }}
          onClick={() => navigate("/home")}
          startIcon={<ArrowBack />}
        >
          <Typography>Regresar</Typography>
        </Button>
      )}
      <Grid container marginTop={2}>
        <Grid xs={12} md={12} lg={6} style={{ position: "relative" }}>
          <Box
            sx={{
              height: "750px",
              width: "400px",
            }}
          >
            <iframe
              style={{
                height: "100%",
                width: "100%",
                border: "0px",
                position: "relative",
              }}
              onLoad={handleIframe}
              src={url}
              title="payment"
              id="payment"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IFrameComponent;
