import axios from "axios";

export const request = () =>
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND,
    headers: {
      authorization: sessionStorage.getItem("authorization"),
    },
  });

export const requestV1 = () =>
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_V1,
    headers: {
      authorization: sessionStorage.getItem("authorization"),
      AccessControlAllowOrigin: "*",
      AccessControlAllowMethods: "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  });
