import { ThemeOptions } from '@mui/material';

export const themeOptions: ThemeOptions = {
    palette: {
        common: {
            black: '#000',
            white: '#FFF',
        },
        primary: {
            main: '#039ECC',
        },
        secondary: {
            main: '#fafafa',
        },
        error: {
            main: '#f44336',
        },
        warning: {
            main: '#FF9E1B',
        },
        success: {
            main: '#5AB52F',
        },
        text: {
            primary: '#212121',
            secondary: '#424242',
            disabled: '#9E9E9E',
        },
    },
    typography: {
        fontFamily: ['Montserrat'].join(','),
    },
};
