import React, { useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import CircularProgress from "@mui/material/CircularProgress";
import { useSearchPoliza } from "../hooks/useSearchPoliza";
import { initialPolicyState } from "../store/usePolicyStore";

export const SearchPoliza = () => {
  const {
    poliza,
    setPoliza,
    serie,
    setSerie,
    error,
    onSearchPress,
    isLoading,
    setIsNewSell,
    isNewSell,
    marca,
    anio,
    niv,
    placas,
    nombre,
    apellido1,
    apellido2,
    sexo,
    fechaNacimiento,
    rfc,
    contratorEmail,
    telefono,
    domicilioFiscal,
    numeroColaborador,
    zip,
    estado,
    estadoCodigo,
    colonia,
    calle,
    numExterior,
    numInterior,
    township_code,
    isQuoteError,
    tipoVehiculoNewSell,
    marcaNewSell,
    anioNewSell,
    modeloNewSell,
    createQuote,
    resetInputs,
    enableCotizar,
    setPolicy,
    setTotal,
    setIsLoadingQuoteNewSell,
    isLoadingQuote,
    isLoadingQuoteNewSell,
    isCopsis,
    showQuoteButton,
    policyURL,
    setIsCopsis,
    setPolicyURL,
    removePolicyUrl,
    setRemovePolicyUrl,
    quoteFinished,
    setQuoteFinished,
  } = useSearchPoliza();

  const isQuoteBtnDisabled = useMemo(
    () =>
      !niv ||
      !placas ||
      !numeroColaborador ||
      !nombre ||
      !apellido1 ||
      !apellido2 ||
      !sexo ||
      !fechaNacimiento ||
      !rfc ||
      !contratorEmail ||
      !telefono ||
      !zip ||
      !estado ||
      !colonia ||
      !calle ||
      !numExterior ||
      !enableCotizar,
    [
      marca,
      anio,
      niv,
      placas,
      numeroColaborador,
      nombre,
      apellido1,
      apellido2,
      sexo,
      fechaNacimiento,
      rfc,
      contratorEmail,
      telefono,
      zip,
      estado,
      colonia,
      calle,
      numExterior,
      enableCotizar,
    ],
  );

  const showSearchButton = quoteFinished ? true : !isNewSell && !isCopsis;

  const showCotizarButton = quoteFinished ? false : isNewSell || isCopsis;

  return (
    <Box
      style={{
        display: "flex",
        width: "350px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          marginBottom: "2px",
        }}
      >
        <Typography alignSelf={"start"} fontSize={"12px"}>
          Póliza
        </Typography>
        <LockIcon color={"primary"} sx={{ width: "14px", height: "14px" }} />
      </Box>
      <TextField
        disabled={isNewSell}
        fullWidth
        size="small"
        variant="outlined"
        style={{
          borderRadius: "10px",
          backgroundColor: isNewSell ? "#f3f6f4" : "white",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.12)",
          marginBottom: "20px",
        }}
        value={poliza}
        onChange={(event) => {
          setPoliza(event.target.value);
        }}
      />
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          marginBottom: "2px",
        }}
      >
        <Typography alignSelf={"start"} fontSize={"12px"}>
          Serie
        </Typography>
        <LockIcon color={"primary"} sx={{ width: "14px", height: "14px" }} />
      </Box>
      <TextField
        disabled={isNewSell}
        fullWidth
        size="small"
        variant="outlined"
        style={{
          borderRadius: "10px",
          backgroundColor: isNewSell ? "#f3f6f4" : "white",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.12)",
        }}
        value={serie}
        onChange={(event) => {
          setSerie(event.target.value);
        }}
      />
      {showSearchButton && (
        <Button
          disabled={isLoading}
          variant="contained"
          color="primary"
          sx={{ width: "140px", alignSelf: "center", marginTop: "20px" }}
          onClick={onSearchPress}
        >
          {isLoading ? "Loading" : "Buscar"}
          {isLoading && (
            <CircularProgress
              sx={{ marginLeft: 1 }}
              size={15}
              color="primary"
            />
          )}
        </Button>
      )}
      {showCotizarButton && (
        <Button
          disabled={isLoadingQuote}
          variant="contained"
          color="primary"
          sx={{ width: "140px", alignSelf: "center", marginTop: "20px" }}
          onClick={createQuote}
        >
          {isLoadingQuote ? "Loading" : "Cotizar"}
          {isLoadingQuote && (
            <CircularProgress
              sx={{ marginLeft: 1 }}
              size={15}
              color="primary"
            />
          )}
        </Button>
      )}

      {isCopsis && policyURL && !removePolicyUrl && (
        <Link
          sx={{ alignSelf: "center", marginTop: 2, marginBottom: 2 }}
          href={policyURL}
          target="_blank"
          rel="noopener"
        >
          Ver poliza
        </Link>
      )}

      <FormGroup
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          checked={isNewSell}
          value={isNewSell}
          onChange={(event) => {
            const target = event.target as HTMLInputElement;
            setIsNewSell(target.checked);
            resetInputs();
            setPoliza("");
            setSerie("");
            setTotal(0);
            setPolicy(initialPolicyState);
            setPolicyURL("");
            setIsCopsis(false);
            setQuoteFinished(false);
          }}
          control={<Checkbox defaultChecked />}
          label="Venta Nueva"
        />
      </FormGroup>
      <Typography marginTop={5} color={"red"}>
        {error}
      </Typography>
    </Box>
  );
};
