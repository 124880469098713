import { useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { usePolicyInputsStore, usePolicyStore } from "../store/usePolicyStore";

export const useDetailsComponent = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isTablet = useMediaQuery(breakpoints.down("md"));

  return {
    isMobile,
    isTablet,
  };
};
