import { useCallback, useEffect } from "react";
import { request } from "../api";

export const useApp = () => {
  const getAuth = useCallback(async () => {
    const { data } = await request().post("/authorization/token", {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    });
    sessionStorage.setItem("authorization", data.access_token);
  }, []);

  useEffect(() => {
    getAuth();
  }, [getAuth]);
};
