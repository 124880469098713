import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useCarInfo,
  usePolicyInputsStore,
  usePolicyStore,
  useQuoteNewSellStore,
} from "../store/usePolicyStore";
import dayjs from "dayjs";
import { request, requestV1 } from "../api";
import { Emission, Quote } from "../api/types";
import { SelectChangeEvent } from "@mui/material";
import { CatalogList } from "./useLeftCard";
import { useNewSellStore } from "../store/useNewSell";

export const useDetailPoliza = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    policy: {
      emission: { request_front },
    },
    total,
    isLoading,
    quoteId,
    setIsLoading,
    setTotal,
    setQuoteId,
    isLoadingQuote,
    setIsLoadingQuote,
    plan,
    setPlan,
    vigencia,
    setVigencia,
    error,
    setError,
    trackingId,
    emissionBody,
    setEmissionBody,
  } = usePolicyStore();
  const { setIsNewSell, isNewSell, isCopsis } = useNewSellStore();

  const {
    anio,
    niv,
    placas,
    nombre,
    apellido1,
    apellido2,
    sexo,
    fechaNacimiento,
    rfc,
    contratorEmail,
    telefono,
    numeroColaborador,
    isQuoteError,
    calle,
    numExterior,
    numInterior,
    colonia,
    estadoCodigo,
    township_code,
    setInputValue,
    zip,
  } = usePolicyInputsStore();
  const { isLoadingQuoteNewSell } = useQuoteNewSellStore();
  const { enableCotizar, carInfo } = useCarInfo();

  const totalByVigencia = useMemo(() => {
    return total / (vigencia === 6 ? 2 : 1);
  }, [total, vigencia]);

  const getPolicyAndQuote = async () => {
    if (isNewSell || isCopsis) {
      const body = {
        tracking_id: trackingId,
        business: "cocheseguro",
        insurance: "mapfre-down-sell",
        contact_name: nombre,
        contact_method: "EMAIL",
        contact_phone: telefono,
        contact_email: contratorEmail,
        contact_gender: +sexo === 1 ? "M" : "F",
        contact_birth_date: fechaNacimiento,
        contact_state_code: estadoCodigo,
        contact_township_code: township_code,
        contact_suburb_code: colonia,
        contact_zip_code: zip,
        contact_now: true,
        vehicle_id: carInfo?.vehicle_id,
        vehicle_service: "PARTICULAR",
        vehicle_use: "NORMAL",
        vehicle_type: carInfo?.type.toUpperCase(),
        payment_option: "A",
        package: +plan === 10 ? "PAQ5" : +plan === 20 ? "PAQ2" : "PAQ3",
        promotion_code: "",
        associate_id: "",
        quote_date: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
        start_at: dayjs().format("YYYY-MM-DD"),
        end_at: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
        custom_RT: "10",
        custom_DM: "5",
      };

      try {
        setTotal(0);
        setError("");
        setIsLoadingQuote(true);

        try {
          const { data: quote } = await request().post<Quote>(`/quote`, body);
          setTotal(+quote.Totales.prima_total);
          setQuoteId(quote.quote_id);
          setIsLoadingQuote(false);
          setEmissionBody({
            ...emissionBody,
            package: +plan === 10 ? "PAQ5" : +plan === 20 ? "PAQ2" : "PAQ3",
            start_at: dayjs().format("YYYY-MM-DD"),
            quote_date: dayjs().format("YYYY-MM-DD"),
            emision_date: dayjs().format("YYYY-MM-DD"),
            end_at: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
            quote_id: quote.quote_id,
          });
        } catch (e) {
          setInputValue("true", "isQuoteError");

          const {
            data: { data },
          } = await requestV1().get<CatalogList>(`/catalogs/location/${zip}`);

          setInputValue(data[0].zip_code, "zip");
          setInputValue(data[0].state_name, "estado");
          setInputValue("", "colonia");
          setInputValue(data[0].state_name, "calle");

          const { data: quote } = await request().post<Quote>(`/quote`, body);
          setTotal(+quote.Totales.prima_total);
          setQuoteId(quote.quote_id);
          setIsLoadingQuote(false);
        }
      } catch (e) {
        //@ts-ignore
        if (e.response && e.response.status === 404) {
          setIsLoadingQuote(false);
          setError(
            "No se pudo encontrar registro con la Poliza or Serie ingresada, intenta con otro.",
          );
        }
      }

      return;
    }

    try {
      setTotal(0);
      setError("");
      setIsLoadingQuote(true);

      const body = {
        tracking_id: trackingId,
        business: request_front.business,
        insurance: "mapfre-down-sell",
        contact_name: nombre,
        contact_method: "EMAIL",
        contact_phone: telefono,
        contact_email: contratorEmail,
        contact_gender: sexo === "1" ? "M" : "F",
        contact_birth_date: fechaNacimiento,
        contact_state_code: request_front.contractor_state_code,
        contact_township_code: request_front.contractor_township_code,
        contact_suburb_code: request_front.contractor_suburb_code,
        contact_zip_code: request_front.contractor_zip_code,
        contact_now: true,
        vehicle_id: request_front.vehicle_id,
        vehicle_service: request_front.vehicle_service,
        vehicle_use: request_front.vehicle_use,
        vehicle_type: request_front.vehicle_type,
        payment_option: "A",
        package: +plan === 10 ? "PAQ5" : +plan === 20 ? "PAQ2" : "PAQ3",
        promotion_code: request_front.promo_code,
        associate_id: request_front.associate_id,
        quote_date: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
        start_at: dayjs().format("YYYY-MM-DD"),
        end_at: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
        custom_RT: "10",
        custom_DM: "5",
      };

      try {
        const { data: quote } = await request().post<Quote>(`/quote`, body);
        setTotal(+quote.Totales.prima_total);
        setQuoteId(quote.quote_id);
        setIsLoadingQuote(false);
      } catch (e) {
        setInputValue("true", "isQuoteError");

        const {
          data: { data },
        } = await requestV1().get<CatalogList>(
          `/catalogs/location/${request_front.contractor_zip_code}`,
        );

        setInputValue(data[0].zip_code, "zip");
        setInputValue(data[0].state_name, "estado");
        setInputValue("", "colonia");
        setInputValue(data[0].state_name, "calle");

        const body = {
          tracking_id: trackingId,
          business: request_front.business,
          insurance: "mapfre-down-sell",
          contact_name: request_front.contractor_first_name,
          contact_method: "EMAIL",
          contact_phone: request_front.contractor_phone,
          contact_email: request_front.contractor_email,
          contact_gender: request_front.contractor_gender,
          contact_birth_date: request_front.contractor_birth_date,
          contact_state_code: request_front.contractor_state_code,
          contact_township_code: data[0].township_code,
          contact_suburb_code: data[0].suburb_code,
          contact_zip_code: request_front.contractor_zip_code,
          contact_now: true,
          vehicle_id: request_front.vehicle_id,
          vehicle_service: request_front.vehicle_service,
          vehicle_use: request_front.vehicle_use,
          vehicle_type: request_front.vehicle_type,
          payment_option: "A",
          package: +plan === 10 ? "PAQ5" : +plan === 20 ? "PAQ2" : "PAQ3",
          promotion_code: request_front.promo_code,
          associate_id: request_front.associate_id,
          quote_date: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
          start_at: dayjs().format("YYYY-MM-DD"),
          end_at: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
          custom_RT: "10",
          custom_DM: "5",
        };
        const { data: quote } = await request().post<Quote>(`/quote`, body);
        setTotal(+quote.Totales.prima_total);
        setQuoteId(quote.quote_id);
        setIsLoadingQuote(false);
      }
    } catch (e) {
      //@ts-ignore
      if (e.response && e.response.status === 404) {
        setIsLoadingQuote(false);
        setError(
          "No se pudo encontrar registro con la Poliza or Serie ingresada, intenta con otro.",
        );
      }
    }
  };

  useEffect(() => {
    if (total !== -1 && total === 0) {
      getPolicyAndQuote();
    }
  }, [plan, vigencia]);

  const handleChangePlan = async (event: SelectChangeEvent<string>) => {
    setPlan(event.target.value as string);
    setTotal(0);
  };

  const handleChangeVigencia = async (event: SelectChangeEvent<string>) => {
    setVigencia(+event.target.value);
    setTotal(0);
  };

  const validacionContractor = useMemo(() => {
    return (
      nombre === request_front.contractor_first_name ||
      telefono === request_front.contractor_phone ||
      rfc === request_front.contractor_rfc ||
      apellido1 === request_front.contractor_lastname ||
      apellido2 === request_front.contractor_second_lastname ||
      sexo === request_front.contractor_gender ||
      fechaNacimiento === request_front.contractor_birth_date ||
      contratorEmail === request_front.contractor_email
    );
  }, [
    nombre,
    telefono,
    rfc,
    apellido1,
    apellido2,
    sexo,
    fechaNacimiento,
    contratorEmail,
    request_front,
  ]);

  const onClickCopiarLigaDePago = async () => {
    setIsLoadingQuote(true);
    setLoading(true);

    const contratorDomicilio = {
      contractor_state_code:
        estadoCodigo || request_front.contractor_state_code,
      contractor_township_code:
        township_code || request_front.contractor_township_code,
      contractor_suburb_code: colonia || request_front.contractor_suburb_code,
      contractor_street: calle || request_front.contractor_street,
      contractor_ext: numExterior || request_front.contractor_ext,
      contractor_int: numInterior || request_front.contractor_int,
    };

    const bodyEmission = {
      tracking_id: request_front.tracking_id,
      quote_id: quoteId,
      business: request_front.business,
      insurance: "mapfre-down-sell",
      package: +plan === 10 ? "PAQ5" : +plan === 20 ? "PAQ2" : "PAQ3",
      payment_plan: "",
      payment_option: "A",
      payment_card: "",
      driver_first_name: request_front.driver_first_name,
      driver_last_name: request_front.driver_last_name,
      driver_second_last_name: request_front.driver_second_last_name,
      driver_phone: request_front.driver_phone,
      driver_mail: contratorEmail,
      driver_gender: request_front.driver_gender,
      driver_birth_date: request_front.driver_birth_date,
      driver_location: request_front.driver_location,
      driver_rfc: request_front.driver_rfc,
      driver_zip_code: request_front.driver_zip_code,
      driver_state_code: request_front.driver_state_code,
      driver_township_code: request_front.driver_township_code,
      driver_suburb_code: request_front.driver_suburb_code,
      driver_street: request_front.driver_street,
      driver_ext: request_front.driver_ext,
      driver_int: request_front.driver_int,
      vehicle_id: request_front.vehicle_id,
      vehicle_service: request_front.vehicle_service,
      vehicle_use: request_front.vehicle_use,
      vehicle_vin: niv,
      vehicle_plate: placas,
      vehicle_type: request_front.vehicle_type,
      vehicle_model: anio,
      legal_agreement: [
        {
          agreement:
            "Estoy de acuerdo con la descripción del vehículo seleccionada y misma que  se  muestra  en  la  parte superior de esta pantalla.",
          acceptance: true,
        },
        {
          agreement:
            "Mi vehículo no es utilizado ni será utilizado para presentar servicios de entrega por paquetería, a domicilio y/o similares.",
          acceptance: true,
        },
        {
          agreement:
            "No he recibido indemnización alguna de parte de alguna aseguradora derivado de la pérdida total de mi vehículo.",
          acceptance: true,
        },
        {
          agreement:
            "Mi vehículo no se encuentra chocado o siniestrado de alguna forma.",
          acceptance: true,
        },
        {
          agreement: "He leído y acepto el Aviso de privacidad.",
          acceptance: true,
        },
        {
          agreement:
            "Estoy de acuerdo con las Condiciones Generales y Términos de Uso.",
          acceptance: true,
        },
      ],
      contractor: validacionContractor ? "false" : request_front.contractor,
      contractor_first_name: nombre,
      contractor_lastname: apellido1,
      contractor_second_lastname: apellido2,
      contractor_birth_date: fechaNacimiento,
      contractor_rfc: rfc,
      contractor_email: contratorEmail,
      contractor_phone: telefono,
      contractor_gender: sexo === "1" ? "M" : "F",
      contractor_zip_code: request_front.contractor_zip_code,
      custom_DM: "5",
      custom_RT: "10",
      legal_zip_code: request_front.legal_zip_code,
      legal_state_code: request_front.legal_state_code,
      legal_township_code: request_front.legal_township_code,
      legal_suburb_code: request_front.legal_suburb_code,
      legal_street: request_front.legal_street,
      legal_ext: request_front.legal_ext,
      legal_int: request_front.legal_int,
      promo_code: request_front.promo_code,
      associate_id: numeroColaborador,
      invoice: "false",
      cfdi_tax_regime: request_front.cfdi_tax_regime,
      start_at: dayjs().format("YYYY-MM-DD"),
      quote_date: dayjs().format("YYYY-MM-DD"),
      emision_date: dayjs().format("YYYY-MM-DD"),
      end_at: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
      ...contratorDomicilio,
    };

    const { data: emission } = await request().post<Emission>(
      `/emission`,
      bodyEmission,
    );

    setLoading(false);
    setIsLoadingQuote(false);

    navigate(`/details/${emission.emission_id}`);
  };

  const onClickCopiarLigaDePagoNewSell = async () => {
    setIsLoadingQuote(true);
    setLoading(true);

    const { data: trackingData } = await request().post<{
      data: { id: string };
    }>(`/tracking`, {
      business: "cocheseguro",
      data: {},
    });

    const body = {
      tracking_id: trackingData.data.id,
      quote_id: quoteId,
      business: "cocheseguro",
      insurance: "mapfre-down-sell",
      package: +plan === 10 ? "PAQ5" : +plan === 20 ? "PAQ2" : "PAQ3",
      payment_plan: "",
      payment_option: "A",
      payment_card: "",
      driver_first_name: nombre,
      driver_last_name: apellido1,
      driver_second_last_name: apellido2,
      driver_phone: telefono,
      driver_mail: contratorEmail,
      driver_gender: +sexo === 1 ? "M" : "F",
      driver_birth_date: fechaNacimiento,
      driver_location: "",
      driver_rfc: rfc,
      driver_zip_code: zip,
      driver_state_code: estadoCodigo,
      driver_township_code: township_code,
      driver_suburb_code: colonia,
      driver_street: calle,
      driver_ext: numExterior,
      driver_int: `${numInterior}`,
      vehicle_id: carInfo?.vehicle_id,
      vehicle_service: "PARTICULAR",
      vehicle_use: "NORMAL",
      vehicle_type: carInfo?.type.toUpperCase(),
      vehicle_vin: niv,
      vehicle_plate: placas,
      vehicle_model: `${carInfo?.model}`,
      legal_agreement: [
        {
          agreement:
            "Estoy de acuerdo con la descripción del vehículo seleccionada y misma que  se  muestra  en  la  parte superior de esta pantalla.",
          acceptance: true,
        },
        {
          agreement:
            "Mi vehículo no es utilizado ni será utilizado para presentar servicios de entrega por paquetería, a domicilio y/o similares.",
          acceptance: true,
        },
        {
          agreement:
            "No he recibido indemnización alguna de parte de alguna aseguradora derivado de la pérdida total de mi vehículo.",
          acceptance: true,
        },
        {
          agreement:
            "Mi vehículo no se encuentra chocado o siniestrado de alguna forma.",
          acceptance: true,
        },
        {
          agreement: "He leído y acepto el Aviso de privacidad.",
          acceptance: true,
        },
        {
          agreement:
            "Estoy de acuerdo con las Condiciones Generales y Términos de Uso.",
          acceptance: true,
        },
      ],
      contractor: "false",
      contractor_first_name: nombre,
      contractor_lastname: apellido1,
      contractor_second_lastname: apellido2,
      contractor_birth_date: fechaNacimiento,
      contractor_rfc: rfc,
      contractor_email: contratorEmail,
      contractor_phone: telefono,
      contractor_gender: +sexo === 1 ? "M" : "F",
      contractor_zip_code: zip,
      custom_DM: "5",
      custom_RT: "10",
      legal_zip_code: zip,
      legal_state_code: estadoCodigo,
      legal_township_code: township_code,
      legal_suburb_code: colonia,
      legal_street: calle,
      legal_ext: numExterior,
      legal_int: `${numInterior}`,
      promo_code: "",
      associate_id: numeroColaborador,
      invoice: "false",
      cfdi_tax_regime: "612",
      start_at: dayjs().format("YYYY-MM-DD"),
      end_at: dayjs().add(vigencia, "months").format("YYYY-MM-DD"),
      quote_date: dayjs().format("YYYY-MM-DD"),
      emision_date: dayjs().format("YYYY-MM-DD"),
      contractor_state_code: estadoCodigo,
      contractor_township_code: township_code,
      contractor_suburb_code: colonia,
      contractor_street: calle,
      contractor_ext: numExterior,
      contractor_int: `${numInterior}`,
    };

    const { data: emission } = await request().post<Emission>(
      `/emission`,
      isCopsis ? body : emissionBody,
    );

    setLoading(false);
    setIsLoadingQuote(false);

    navigate(`/details/${emission.emission_id}`);
  };

  return {
    isLoading,
    isLoadingQuote,
    request_front,
    plan,
    handleChangePlan,
    vigencia,
    handleChangeVigencia,
    total,
    loading,
    setIsLoading,
    setIsLoadingQuote,
    setLoading,
    quoteId,
    niv,
    placas,
    anio,
    navigate,
    nombre,
    apellido1,
    apellido2,
    fechaNacimiento,
    rfc,
    sexo,
    contratorEmail,
    telefono,
    error,
    onClickCopiarLigaDePago,
    totalByVigencia,
    numeroColaborador,
    isQuoteError,
    colonia,
    calle,
    numExterior,
    setIsNewSell,
    isNewSell,
    onClickCopiarLigaDePagoNewSell,
    isLoadingQuoteNewSell,
  };
};
