import React, { useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  InputAdornment,
  IconButton,
  LinearProgress,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import { usePolicyInputsStore, usePolicyStore } from "../store/usePolicyStore";
import { request } from "../api";
import { Emission, Quote } from "../api/types";
import { Root } from "../store/types";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { useDetailPoliza } from "../hooks/useDetailPoliza";
import { convertCurrency } from "../utils/convertCurrency";
import { useNewSellStore } from "../store/useNewSell";

const DetailPoliza: React.FC = () => {
  const {
    isLoading,
    isLoadingQuote,
    request_front,
    plan,
    handleChangePlan,
    vigencia,
    handleChangeVigencia,
    total,
    loading,
    error,
    onClickCopiarLigaDePago,
    totalByVigencia,
    numeroColaborador,
    isQuoteError,
    colonia,
    calle,
    numExterior,
    isNewSell,
    onClickCopiarLigaDePagoNewSell,
    isLoadingQuoteNewSell,
  } = useDetailPoliza();
  const { isCopsis, setIsCopsis } = useNewSellStore();

  return (
    <Box
      style={{
        width: "400px",
        padding: "25px",
        border: "1px solid gray",
        borderRadius: "20px",
        marginTop: "45px",
      }}
    >
      <Box
        style={{
          borderBottomStyle: "dashed",
          borderBottom: "1px dashed #5E6A71",
          paddingBottom: "30px",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold" }}
            textAlign="start"
            fontSize={16}
          >
            Detalle
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "bold" }}
            textAlign="start"
            fontSize={15}
          ></Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={1}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal" }}
            textAlign="start"
            fontSize={15}
          >
            Modalidad de pago
          </Typography>
          <Box
            display={"flex"}
            width={"200px"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              style={{
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.12)",
              }}
              disabled={true}
              value={"Contado"}
              onChange={() => {}}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={1}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal" }}
            textAlign="start"
            fontSize={15}
          >
            Tipo de plan
          </Typography>
          <FormControl variant="outlined" sx={{ width: "200px" }}>
            {!isNewSell && (
              <Select
                disabled={
                  isLoading ||
                  request_front.quote_id === "" ||
                  isLoadingQuote ||
                  error !== "" ||
                  colonia === "" ||
                  !isCopsis
                }
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={plan}
                onChange={handleChangePlan}
                label="Tipo de Plan"
                size="small"
              >
                {/*<MenuItem value={10}>Amplia 40</MenuItem>*/}
                <MenuItem value={20}>Amplia 20</MenuItem>
                <MenuItem value={30}>RC Básica</MenuItem>
              </Select>
            )}
            {isNewSell && (
              <Select
                disabled={!isLoadingQuoteNewSell || isLoadingQuote}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={plan}
                onChange={handleChangePlan}
                label="Tipo de Plan"
                size="small"
              >
                {/*<MenuItem value={10}>Amplia 40</MenuItem>*/}
                <MenuItem value={20}>Amplia 20</MenuItem>
                <MenuItem value={30}>RC Básica</MenuItem>
              </Select>
            )}
          </FormControl>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={1}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "normal" }}
            textAlign="start"
            fontSize={15}
          >
            Vigencia
          </Typography>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <FormControl variant="outlined" sx={{ width: "200px" }}>
              {!isNewSell && (
                <Select
                  disabled={
                    isLoading ||
                    request_front.quote_id === "" ||
                    isLoadingQuote ||
                    error !== "" ||
                    colonia === "" ||
                    !isCopsis
                  }
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={vigencia.toString()}
                  onChange={handleChangeVigencia}
                  label="Vigencia"
                  size="small"
                >
                  <MenuItem value={6}>Semestral</MenuItem>
                  <MenuItem value={12}>Anual</MenuItem>
                </Select>
              )}
              {isNewSell && (
                <Select
                  disabled={!isLoadingQuoteNewSell || isLoadingQuote}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={vigencia.toString()}
                  onChange={handleChangeVigencia}
                  label="Vigencia"
                  size="small"
                >
                  <MenuItem value={6}>Semestral</MenuItem>
                  <MenuItem value={12}>Anual</MenuItem>
                </Select>
              )}
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginTop={4}
        borderRadius={2}
      >
        <Box
          width={278}
          height={70}
          borderRadius={"10px 0 0 10px "}
          style={{ backgroundColor: "#e3f2fd" }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            height={70}
          >
            <Box
              component={"img"}
              src={
                "https://e7.pngegg.com/pngimages/540/1020/png-clipart-mapfre-insurance-oficina-seguros-mapfre-mapfre-seguros-gerais-s-a-logo-farmers-insurance-text-trademark.png"
              }
              sx={{
                width: "55%",
                height: "100%",
                margin: "0",
                padding: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              alt={"company logo"}
              style={{ objectFit: "contain" }}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={208}
          height={70}
          borderRadius={"0 10px 10px 0"}
          style={{ backgroundColor: "#e8eaf6" }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            padding={1}
          >
            <Typography variant="subtitle2" color="#8F8F8F" fontSize={16}>
              Costo
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "bold", display: "flex", gap: "0.5rem" }}
              fontSize={17}
            >
              <span>$</span>
              {total === -1 ? "0.00" : convertCurrency(total)}
            </Typography>
            <Typography fontSize={12} style={{ color: "#8F8F8F" }}></Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", marginTop: 3 }}>
        <Typography
          sx={{ fontSize: 25, marginLeft: 1, marginRight: 1 }}
          color={"error"}
        >
          *
        </Typography>
        <Typography sx={{ display: "flex", alignItems: "center" }}>
          Revisa los campos obligatorios marcados con asterico rojo para
          habilitar el botón y poder emitir.
        </Typography>
      </Box>

      {!isNewSell && !isCopsis && (
        <Button
          disabled={
            request_front.quote_id === "" ||
            loading ||
            isLoadingQuote ||
            error !== "" ||
            numeroColaborador === "" ||
            colonia === "" ||
            calle === "" ||
            numExterior === "" ||
            isCopsis
          }
          variant="contained"
          color="primary"
          sx={{ marginTop: "20px", width: "100%" }}
          onClick={onClickCopiarLigaDePago}
        >
          {isLoadingQuote ? "Loading" : "Copiar y enviar liga de pago"}
          {isLoadingQuote && (
            <CircularProgress
              sx={{ marginLeft: 1 }}
              size={15}
              color={"primary"}
            />
          )}
        </Button>
      )}
      {(isNewSell || isCopsis) && (
        <Button
          disabled={
            numeroColaborador === "" ||
            colonia === "" ||
            calle === "" ||
            numExterior === "" ||
            isLoadingQuote ||
            !isLoadingQuoteNewSell
          }
          variant="contained"
          color="primary"
          sx={{ marginTop: "20px", width: "100%" }}
          onClick={onClickCopiarLigaDePagoNewSell}
        >
          {isLoadingQuote ? "Loading" : "Copiar y enviar liga de pago"}
          {isLoadingQuote && (
            <CircularProgress
              sx={{ marginLeft: 1 }}
              size={15}
              color={"primary"}
            />
          )}
        </Button>
      )}
    </Box>
  );
};

export default DetailPoliza;
