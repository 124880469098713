import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios, { AxiosError, AxiosResponse } from "axios";

export const useSignIn = () => {
  const [alert, setAlert] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>();
  const [loadingGoogle, setLoadingGoogle] = useState<boolean>(false);
  const [showGoogle] = useState<boolean>(true);
  const [showForm] = useState<boolean>();
  const navigate = useNavigate();

  useEffect(() => {
    // update title
    window.document.title = "Signin";

    let cookies = new Cookies();
    let _tid = cookies.get("_tid");
    if (_tid) {
      navigate("/home");
    }
  }, [navigate]);

  //@ts-ignore
  const redirect = (payload) => {
    let cookies = new Cookies();
    cookies.set("_tid", payload.access_token, {
      expires: new Date(payload.expires_in),
    });
    if (payload?.user) {
      cookies.set("_user", btoa(JSON.stringify(payload?.user)));
    }
    navigate("/home");
  };

  //@ts-ignore
  const handleGoogle = (payload) => {
    axios
      .post(process.env.REACT_APP_GOOGLE_AUTH_URL as string, {
        credential: payload.credential,
      })
      .then((response: AxiosResponse) => response.data)
      .then((response) => redirect(response))
      .catch((error: AxiosError) => {
        setAlertContent(String(error.response?.data));
        setAlert(true);
        setLoadingGoogle(false);
      });
  };

  return {
    loading,
    loadingGoogle,
    setAlert,
    alertContent,
    handleGoogle,
    showGoogle,
    setLoadingGoogle,
    setAlertContent,
    alert,
  };
};
