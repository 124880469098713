import DetailsComponent from "../components/DetailsComponent";
import IFrameComponent from "../components/IFrameComponent";
import { Box } from "@mui/material";
import { useDetailPage } from "../hooks/useDetailPage";

const DetailsPage = () => {
  const {
    paymentUrl,
    isTablet,
    total,
    anio,
    marca,
    niv,
    placas,
    plan,
    vehicleType,
    vigencia,
    showBackButton,
  } = useDetailPage();

  if (!paymentUrl) {
    return <></>;
  }

  return (
    <>
      <Box
        style={{
          display: "flex",
          margin: "auto",
          width: "80%",
          justifyContent: "center",
          alignItems: "F",
          gap: "40px",
          flexDirection: isTablet ? "column" : "row",
          marginTop: isTablet ? "130px" : "110px",
          marginBottom: isTablet ? "130px" : "60px",
        }}
      >
        <IFrameComponent url={paymentUrl} showBackButton={showBackButton} />
        <DetailsComponent
          total={total}
          anio={anio}
          marca={marca}
          niv={niv}
          placas={placas}
          plan={plan}
          vehicleType={vehicleType}
          vigencia={vigencia}
        />
      </Box>
    </>
  );
};

export default DetailsPage;
