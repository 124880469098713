import { SelectChangeEvent, useMediaQuery, useTheme } from "@mui/material";
import { usePolicyInputsStore, usePolicyStore } from "../store/usePolicyStore";
import { useEffect, useState } from "react";
import { request, requestV1 } from "../api";
import axios from "axios";
import { useNewSellStore } from "../store/useNewSell";

interface Catalog {
  zip_code: string;
  state_code: string;
  state_name: string;
  suburb_code: string;
  suburb_name: string;
  city_name: string;
  township_code: string;
  township_name: string;
}

export interface CatalogList {
  data: Catalog[];
}

export const useLeftCard = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isTablet = useMediaQuery(breakpoints.down("md"));
  const {
    policy: {
      emission: { request_front },
    },
  } = usePolicyStore();
  const { isNewSell } = useNewSellStore();

  const {
    marca,
    anio,
    niv,
    placas,
    nombre,
    apellido1,
    apellido2,
    sexo,
    fechaNacimiento,
    rfc,
    contratorEmail,
    telefono,
    domicilioFiscal,
    setInputValue,
    numeroColaborador,
    zip,
    estado,
    colonia,
    calle,
    numExterior,
    numInterior,
    isQuoteError,
    tipoVehiculoNewSell,
    marcaNewSell,
    anioNewSell,
    modeloNewSell,
    disabledInputs,
    colonias,
    setColonias,
  } = usePolicyInputsStore();

  const handleSexo = (event: SelectChangeEvent<string>) => {
    setInputValue(event.target.value as string, "sexo");
  };

  const handleColonia = (event: SelectChangeEvent<string>) => {
    setInputValue(event.target.value, "colonia");
  };

  const getCatalogsLocation = async (zip: string) => {
    if (zip === "") return;

    try {
      const {
        data: { data },
      } = await requestV1().get<CatalogList>(`/catalogs/location/${zip}`);

      if (isQuoteError === "true") {
        setInputValue(data[0].zip_code, "zip");
        setInputValue(data[0].state_name, "estado");
        setInputValue("", "colonia");
        setInputValue(data[0].state_name, "calle");
        const coloniasArray = data.map((catalog) => ({
          suburb_name: catalog.suburb_name,
          suburb_code: catalog.suburb_code,
        }));
        setInputValue(data[0].township_code, "township_code");
        setColonias(coloniasArray);
      } else {
        const estadoObj = data.find(
          (catalog) =>
            catalog.state_code === request_front.contractor_state_code,
        );
        setInputValue(request_front.contractor_zip_code, "zip");
        setInputValue(estadoObj!.state_name, "estado");
        setInputValue(estadoObj!.state_code, "estadoCodigo");
        setInputValue(request_front.contractor_suburb_code, "colonia");
        setInputValue(request_front.contractor_street, "calle");
        setInputValue(request_front.contractor_township_code, "township_code");
        const coloniasObj = data.find(
          (catalog) =>
            catalog.suburb_code === request_front.contractor_suburb_code,
        );

        setColonias([
          {
            suburb_name: coloniasObj!.suburb_name,
            suburb_code: coloniasObj!.suburb_code,
          },
        ]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getCatalogsLocationNewSell = async (zip: string) => {
    if (zip === "") return;

    try {
      const {
        data: { data },
      } = await requestV1().get<CatalogList>(`/catalogs/location/${zip}`);

      setInputValue(data[0].zip_code, "zip");
      setInputValue(data[0].state_name, "estado");
      setInputValue("", "colonia");
      setInputValue(data[0].state_name, "calle");
      setInputValue(data[0].state_code, "estadoCodigo");
      const coloniasArray = data.map((catalog) => ({
        suburb_name: catalog.suburb_name,
        suburb_code: catalog.suburb_code,
      }));
      setInputValue(data[0].township_code, "township_code");
      setColonias(coloniasArray);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (nombre === "") {
      setInputValue(request_front.vehicle_description, "marca");
      setInputValue(request_front.vehicle_model, "anio");
      setInputValue(request_front.vehicle_vin, "niv");
      setInputValue(request_front.vehicle_plate, "placas");
      setInputValue(request_front.contractor_first_name, "nombre");
      setInputValue(request_front.contractor_lastname, "apellido1");
      setInputValue(request_front.contractor_second_lastname, "apellido2");
      setInputValue(
        request_front.contractor_gender.toLowerCase() === "m" ? "1" : "2",
        "sexo",
      );
      setInputValue(request_front.contractor_birth_date, "fechaNacimiento");
      setInputValue(request_front.contractor_rfc, "rfc");
      setInputValue(request_front.contractor_email, "contratorEmail");
      setInputValue(request_front.contractor_phone, "telefono");
      const {
        contractor_street,
        contractor_state_code,
        contractor_suburb_code,
        contractor_zip_code,
      } = request_front;
      setInputValue(
        contractor_street &&
          `${contractor_street} CODIGO ESTADO ${contractor_state_code} CODIGO SUBURBIO ${contractor_suburb_code} CP ${contractor_zip_code}`,
        "domicilioFiscal",
      );
      setInputValue(request_front.contractor_ext, "numExterior");
      setInputValue(request_front.contractor_int, "numInterior");
    }
  }, [request_front]);

  useEffect(() => {
    getCatalogsLocation(request_front.contractor_zip_code);
  }, [isQuoteError, request_front]);

  return {
    isMobile,
    isTablet,
    request_front,
    setInputValue,
    marca,
    anio,
    niv,
    placas,
    nombre,
    apellido1,
    apellido2,
    sexo,
    handleSexo,
    fechaNacimiento,
    rfc,
    contratorEmail,
    telefono,
    domicilioFiscal,
    numeroColaborador,
    zip,
    estado,
    colonia,
    calle,
    numExterior,
    numInterior,
    colonias,
    handleColonia,
    isNewSell,
    getCatalogsLocationNewSell,
    tipoVehiculoNewSell,
    marcaNewSell,
    anioNewSell,
    modeloNewSell,
    disabledInputs,
    setColonias,
  };
};
