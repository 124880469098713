import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { socket } from "../utils/socket";

export interface ISocketNotification {
  section?: "appbar" | "notification";
  type: string;
  message: string;
}

export const useHome = () => {
  const navigate = useNavigate();
  window.document.title = "Seguro Indemnizatorio";
  {
    /* validate the cookie in the useEffect */
  }
  useEffect(() => {
    let cookies = new Cookies();
    let _tid = cookies.get("_tid");
    let _user = cookies.get("_user");

    function onConnect() {
      window.console.log("Connected with the socket");
    }

    if (!_tid) {
      navigate("/signin");
    } else {
      socket.io.opts.query = {
        _u: _user,
      };
      socket.connect();
      socket.on("connect", onConnect);
      socket.on("identity", (data: ISocketNotification) => {});
    }
  }, [navigate, socket]);

  const handleLogout = () => {
    /* removing the cookie here */
    let cookies = new Cookies();
    cookies.remove("_tid");
    cookies.remove("_user");
    socket.disconnect();
    window.location.href = "/signin";
  };

  return { handleLogout };
};
