import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { LeftCard } from "./LeftCard";
import { useHome } from "../hooks/useHome";
import { SearchPoliza } from "./SearchPoliza";
import Detail from "./DetailPoliza";

const HomeDownSell: React.FC = () => {
  const { handleLogout } = useHome();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "40px",
        justifyContent: "center",
        marginTop: "120px",
        marginBottom: "60px",
      }}
    >
      <Button
        variant="contained"
        sx={{ position: "absolute", top: 30 }}
        onClick={handleLogout}
      >
        <Typography>Logout</Typography>
      </Button>
      <LeftCard />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SearchPoliza />
        <Detail />
      </Box>
    </Box>
  );
};

export default HomeDownSell;
